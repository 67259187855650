import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../seo"
import Header from "../Header/Header.jsx"
import Footer from "../Footer/Footer.jsx"
import Img from 'gatsby-image'
import MailingListSignup from "../MailingListSignup/MailingListSignup.jsx"

import rehypeReact from "rehype-react"

import MultiStepCalculator from "../MultiStepCalculator/MultiStepCalculator.jsx"
import FreelanceSalaryCalculator from "../FreelanceSalaryCalculator/FreelanceSalaryCalculator.jsx"


import "./BlogPost.scss"

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      fields{
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        coverImage {
          childImageSharp {
            fluid(maxWidth: 630) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    jessicaHeadshot: file(relativePath: { eq: "headshots/jessica.jpg" }) {
      childImageSharp{
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "runwaycalculator": MultiStepCalculator,
    "salarycalculator": FreelanceSalaryCalculator
  }
}).Compiler

class BlogPost extends React.Component {

  renderCoverImage = () => {
    if (!this.props.data.markdownRemark.frontmatter.coverImage) {
      return ""
    }

    return (
      <div className="cover-image">
        <Img fluid={this.props.data.markdownRemark.frontmatter.coverImage.childImageSharp.fluid} />
      </div>
    )
  }
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    const title = post.frontmatter.title || post.fields.slug
    let jessicaHeadshot = this.props.data.jessicaHeadshot.childImageSharp.fluid
    
    return (
      <React.Fragment>
        <SEO title={`${post.frontmatter.title} - Blog`}
             description={post.frontmatter.description || post.excerpt}
        />

        <Header />

        <div className="blog-post">
          <div className="container px-4 px-sm-0">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">

                <div className="post-meta">
                  <h6 className="post-date section-title">
                    <time>{post.frontmatter.date}</time>
                  </h6>
                  <h1 className="post-title">
                    <Link to={`/blog${post.fields.slug}`}>
                      {title}
                    </Link>
                  </h1>
                </div>

                {/* {this.renderCoverImage()} */}
                <div className="post-body">
                  {renderAst(post.htmlAst)}
                </div>
                <MailingListSignup signupLocation="Blog Footer"/>

              </div>

              <div className="about-blurb col-sm-12 col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <div className="row">
                  <div className="col-3">
                    <Img className="blog-avatar" fluid={jessicaHeadshot} />
                  </div>
                  <div className="col-9 my-auto">
                    <h6>By Jessica Gillis</h6>
                    <p>Freelance UX/UI Product Designer &amp; Founder of Hammock</p>
                    <ul className="links">
                      <li>
                        <a href="mailto:jessica@tryhammock.com?subject=Hi Friend!" target="_blank" rel="noopener noreferrer">Email</a>
                      </li>

                      <li>
                        <a href="https://www.linkedin.com/in/jessicagillis1/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                      </li>
                      <li>
                        <a href="https://www.jessicagillis.com/" target="_blank" rel="noopener noreferrer">Portfolio</a>
                      </li>
                      <li>
                        <a href="https://twitter.com/jessicagillis5" target="_blank" rel="noopener noreferrer">Twitter</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="post-footer col-sm-12 col-md-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <ul>
                  <li className="prev-post">
                    {previous && (
                      <React.Fragment>
                        <h6 className="post-nav-label">Previous</h6>
                        <Link to={`blog${previous.fields.slug}`} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      </React.Fragment>
                    )}
                  </li>
                  <li className="next-post">
                    {next && (
                      <React.Fragment>
                        <h6 className="post-nav-label">Next</h6>
                        <Link to={`blog${next.fields.slug}`} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      </React.Fragment>
                    )}
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    )
  }
}

export default BlogPost
