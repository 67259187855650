import React from "react"

import "./FreelanceSalaryCalculator.scss"


class FreelanceSalaryCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      taxPercent: 40,
      taxCoefficient: 0.6,
      categories: {
        "idx1": {
          label: "Mortgage or Rent",
          value: 0,
        },
        "idx2": {
          label: "Transportation (bus fare, car payment, insurance, gas, maintenance, etc)",
          value: 0,
        },
        "idx3": {
          label: "Utilities (Phone, Internet, Gas, Electric, etc)",
          value: 0,
        },
        "idx4": {
          label: "Health Insurance",
          value: 0,
        },
        "idx5": {
          label: "Groceries, Eating Out & Coffee Shops",
          value: 0,
        },
        "idx6": {
          label: "Entertainment",
          value: 0,
        },
        "idx7": {
          label: "Student Loans",
          value: 0,
        },
        "idx8": {
          label: "Savings & Retirement",
          value: 0,
        },
        "idx9": {
          label: "Other",
          value: 0,
        },  
      }
    }
  }

  componentDidMount() {

  }

  handleLabelUpdate = (e) => {
    e.preventDefault()
    let idx = e.target.dataset.idx

    this.setState({
      categories: {
        ...this.state.categories,
        [idx]: {
          label: e.target.value,
          value: this.state['categories'][idx].value
        }
      }
    })
  }

  handleTaxRateUpdate = (e) => {
    e.preventDefault()
    let taxPercent = Number(e.target.value)
    this.setState({
      taxPercent: taxPercent,
      taxCoefficient: 1.0 - (taxPercent / 100)
    })
  }

  handleValueUpdate = (e) => {
    e.preventDefault()
    let idx = e.target.dataset.idx

    this.setState({
      categories: {
        ...this.state.categories,
        [idx]: {
          label: this.state['categories'][idx].label,
          value: Number(e.target.value),
        }
      }
    })
  }

  sumExpenses = () => {
    const values = Object.keys(this.state.categories).map((key) => Number(this.state['categories'][key].value))
    return values.reduce((a, b) => a + b, 0).toFixed(2)  // Sum array of numbers
  }

  taxAmount = () => {
    return (this.calculateMonthlySalary() - this.sumExpenses()).toFixed(2)
  }

  calculateMonthlySalary = () => {
    let expenses = this.sumExpenses()
    let salaryPlusTax = expenses / this.state.taxCoefficient
    // let taxAmount = salaryPlusTax - expenses
    return salaryPlusTax.toFixed(2)
  }

  calculateYearlySalary = () => {
    return (this.calculateMonthlySalary() * 12).toFixed(2)
  }

  formatNumber = (x) => {
    return parseInt(x, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="salary-calculator">
            <form className="form">
              <div className="form-row">
                <div className="col-6 col-sm-8">
                  <strong>Category</strong>
                </div>
                <div className="col-6 col-sm-3 ml-auto">
                  <strong>Monthly Budget</strong>
                </div>
              </div>

              {Object.keys(this.state.categories).map((key) => {
                let category = this.state['categories'][key]
                if (category === undefined) {
                  return ""
                }
                return (
                  <div className="form-row" key={key}>
                    <input className="col-6 col-sm-8 form-control" data-idx={key} type="text" value={category.label} onChange={this.handleLabelUpdate}></input>

                    <div className="input-group col-6 col-sm-3 ml-auto">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input type="number" data-idx={key} className="form-control" placeholder="0" onChange={this.handleValueUpdate} />
                    </div>
                  </div>
                );
              })}
              <div className="form-row text-danger">
                <div className="col-8">
                  <strong>Total Monthly Budget</strong>
                </div>

                <div className="col-4 ml-auto text-right">
                  <strong>$ {this.formatNumber(this.sumExpenses())}</strong>
                </div>
              </div>

              <div className="form-row text-danger">
                <div className="col-6">
                  <strong>Estimated Tax</strong>
                </div>

                <div className="input-group col-6 col-sm-3 ml-auto tax-percent">
                  <input type="text" className="form-control" value={this.state.taxPercent} onChange={this.handleTaxRateUpdate} />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>

              <div className="form-row text-danger">
                <div className="col-6">
                  <strong>Est. Taxes Owed</strong>
                </div>

                <div className="col-6 ml-auto text-right">
                  <strong>$ {this.formatNumber(this.taxAmount())}</strong>
                </div>
              </div>

            </form>

            <div className="row">
              <div className="col-md-12 calculator-summary">
                <h4>To cover your <strong>{`$${this.formatNumber(this.sumExpenses())}`} monthly budget</strong>, you'll need to earn <strong>{`$${this.formatNumber(this.calculateMonthlySalary())}`} every month</strong>, and you'll owe around <strong>{`$${this.formatNumber(this.taxAmount())}`} in taxes</strong>.</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="salary-box">
                  <h6>Monthly Paycheck</h6>
                  <h3>{`$${this.formatNumber(this.sumExpenses())}`}</h3>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="salary-box">
                  <h6>Monthly Salary</h6>
                  <h3>{`$${this.formatNumber(this.calculateMonthlySalary())}`}</h3>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="salary-box">
                  <h6>Yearly Salary</h6>
                  <h3>{`$${this.formatNumber(this.calculateYearlySalary())}`}</h3>
                </div>
              </div>
            </div>
            <p className="disclaimer"><em>Disclaimer: This tool is meant to help you get rough estimates. It should not be considered financial or tax advice. I am not an accountant, so if you need advanced help, please seek the advice of a certified professional.</em></p>
          </div>
        </div>
      </div>
    )
  }
}

export default FreelanceSalaryCalculator


